<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <div class="pt-3">
      <i class="fa fa-backward"></i>
      <router-link :to="{ name: PublisherEmailPath.name }"> Email Reports </router-link>
    </div>
    <b-card header="Add Email Report" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
      <b-row>
        <b-col>
          <b-form @submit="onSubmit" @reset="onReset">
            <div class="row">
              <div class="col-lg-6" v-if="auth.role_id == SUPER_ADMIN">
                <b-form-group>
                  <multiselect v-model="form.vendor" label="name" track-by="name" placeholder="Enter Vendor"
                    open-direction="bottom" :options="vendorSearchOptions" :searchable="true" :loading="isVendorSearch"
                    :close-on-select="true" :multiple="false" @search-change="vendorFind">
                  </multiselect>
                </b-form-group>
              </div>
              <div class="col-lg-6">
                <b-form-group>
                  <multiselect placeholder="Scoop Report" track-by="name" label="name" v-model="form.scoop"
                    :options="scoopOptions" :multiple="true" :close-on-select="false"></multiselect>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <b-form-group>
                  <b-form-input id="name" v-model="form.name" type="text" placeholder="Name" required>
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-6">
                <b-form-group>
                  <b-form-input id="email" v-model="form.email" type="email" placeholder="Email" required>
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="col-12">
                <b-form-group>
                  <b-form-checkbox v-model="form.is_active" value="true" unchecked-value="false">Active
                  </b-form-checkbox>
                </b-form-group>
              </div>
              <div class="col-12 text-right">
                <b-form-group>
                  <b-button class="mx-1" type="reset" variant="secondary">Reset</b-button>
                  <b-button class="mx-1" type="submit" variant="primary">Submit</b-button>
                </b-form-group>
              </div>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>

</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { PublisherEmailPath } from "../../../router/account";
import constant from "../../../store/constant";
export default {
  data() {
    return {
      PublisherEmailPath,
      isVendorSearch: false,
      vendorSearchOptions: [],
      form: {
        name: '',
        vendor: null,
        email: '',
        scoop: [],
        is_active: false,
      },
      scoopOptions: Object.values(constant.REPORT_SCOOP).map(val => ({ name: val })),
      SUPER_ADMIN: constant.ROLES.SUPER_ADMIN,
    };
  },
  computed: {
    ...mapGetters("auth", { auth: "auth" }),
    ...mapState({
      isLoading: (state) => state.emailReport.isLoading,
      isError: (state) => state.emailReport.isError,
      errorMessage: (state) => state.emailReport.errorMessage,
      successMessage: (state) => state.emailReport.successMessage,
      emails: (state) => state.emailReport.items,
      totalRows: (state) => state.emailReport.totalRows,
    }),
  },
  watch: {
    errorMessage: function(message) {
      if (!this.errorMessage) return;
      this.messageAlert('error', message, 5000);
    },
    successMessage: function(message) {
      if (!this.successMessage) return;
      this.messageAlert('success', message);
      this.onReset();
    },
  },
  methods: {
    ...mapActions('vendors', ['searchVendors']),
    ...mapActions('emailReport', [
      'postEmailReport',
      'updateEmailReport',
    ]),
    async onSubmit(event) {
      event.preventDefault();
      const payload = {
        name: this.form.name,
        email: this.form.email,
        vendor_id: this.form.vendor?.id,
        is_active: this.form.is_active,
        scoop: (this.form.scoop || []).map(val => val.name),
      }
      await this.postEmailReport(payload);
    },
    onReset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    vendorFind(query) {
      if (!query) return;

      this.isVendorSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          limit: 10,
        };
        this.searchVendors(payload).then((response) => {
          this.vendorSearchOptions = response.data.data.rows;
          this.isVendorSearch = false;
        }).catch(() => {
          this.isVendorSearch = false;
        });
      }, 600);
    },
    messageAlert(icon, title, timer=3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
    
  }
}
</script>